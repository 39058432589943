.pageContainer {
    height: 100dvh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    z-index: 101;
    position: relative;
    background: #0C0B1A;
    width: 100%;
    padding: 40px 20px;
}

.bgImg {
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    z-index: -1;
}

.fourthBg {
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    height: 100%;
    z-index: -1;
}

.titleContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-bottom: 8px;
}

.title {
    color: rgba(255, 255, 255, 0.7);
    text-align: center;
    text-shadow: 0 79px 22px rgba(0, 0, 0, 0.00), 0 51px 20px rgba(0, 0, 0, 0.01), 0 29px 17px rgba(0, 0, 0, 0.03), 0 13px 13px rgba(0, 0, 0, 0.05), 0 3px 7px rgba(0, 0, 0, 0.05);
    font-family: Poppins, sans-serif;
    font-size: 13px;
    font-weight: 500;
    line-height: 25px;
    margin-bottom: 7px;
}

.subtitle {
    font-family: Rubik, sans-serif;
    font-size: 50px;
    font-weight: 700;
    line-height: 56px;
    letter-spacing: 0.02em;
    text-align: center;
    width: 270px;
    color: #FFFFFF;
    margin-top: 44px;
}

.additionalText {
    position: absolute;
    bottom: 32px;
    left: 0;
    width: 100%;
    font-family: Rubik,sans-serif;
    font-size:30px;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: 0.02em;
    text-align: center;
    color: #FFFFFF;
}

.banner {
    background: rgba(255, 255, 255, 0.07);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 48px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
}

.banner img {
    width: 100%;
}

.info {
    padding: 30px 24px;
    background: rgba(255, 255, 255, 0.08);
    width: 100%;
    display: flex;
    gap: 16px;
    align-items: center;
}

.icon {
    width: 56px;
    height: 56px;
    min-width: 56px;
    min-height: 56px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.description {
    font-family: Poppins,sans-serif;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
    color: #FFFFFF;
}

.imgContainer {
    padding: 10px 3px;
}

.iconContainer {
    padding: 10px;
}

.infoContainer {
    padding: 8px 0 16px;
    position: fixed;
    bottom: 0;
    left: 0;
    justify-content: center;
    align-items: center;
    display: flex;
    width: 100%;
    gap: 16px;
    flex-direction: column;
    background: #0C0B1A;
}

.loader {
    animation: spin 1s linear infinite;
    height: 32px;
    width: 32px;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.text {
    color: #FFF;
    text-align: center;
    text-shadow: 0 79px 22px rgba(0, 0, 0, 0.00), 0 51px 20px rgba(0, 0, 0, 0.01), 0 29px 17px rgba(0, 0, 0, 0.03), 0 13px 13px rgba(0, 0, 0, 0.05), 0 3px 7px rgba(0, 0, 0, 0.05);
    font-family: Poppins, sans-serif;
    font-size: 16px;
    font-weight: 500;
}

.linksContainer {
    display: flex;
    gap: 12px;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.link {
    width: 48px;
    height: 48px;
    border-radius: 100px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    background: rgba(255, 255, 255, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
}

@media screen and (max-height: 640px) {
    .banner img {
        width: 80%;
    }
    .subtitle {
        margin-top: 12px;
    }
}
