.container {
    padding: 12px 0 28px;
    display: flex;
    position: fixed;
    justify-content: center;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 100;
    backdrop-filter: blur(40px);
}

.levelIcon {
    position: relative;
    display: flex;
    color: rgba(182, 185, 216, 1);
}

.agoContainer {
    display: flex;
    justify-content: center;
}

.agoIcon {
    width: 40px;
    height: 40px;
    margin-top: -4px;
}

.active {
    color: #FFFFFF;
}

.counter {
    display: flex;
    width: 100%;
    height: 100%;
    position: absolute;
    justify-content: center;
    align-items: center;
    font-family: Roboto;
    font-size: 13px;
    font-weight: 900;
    line-height: 13px;
    letter-spacing: -0.09em;
    text-align: center;
    color: #000000;
}