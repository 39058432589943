.layout {
    height: 100dvh;
    overflow: hidden;
}

.btn {
    position: fixed;
    z-index: 100000;
    top: 50%;
    left: 10px;
}