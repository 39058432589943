.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    overflow: hidden;
    padding: 16px 0;
}

.modalContainer {
    margin: 0 auto;
    overflow: auto;
    max-height: calc(100dvh - 32px);
    min-width: 320px;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
}

.modal {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    border-radius: 40px;
    width: 100%;
    max-width: 358px;
    min-height: 658px;
    border: none;
    overflow: hidden;
    padding-bottom: 32px;
}

.header {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    height: 44px;
    padding: 20px 20px 0 0
}

.close {
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.3);
    border-radius: 100px;
    z-index: 2;
}

.contentContainer {
    margin-top: -20px;
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
}

.goldText {
    margin-top: 6px;
    background: linear-gradient(180deg, #FFF971 25%, #FFF 75%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    -webkit-text-stroke-width: 1;
    -webkit-text-stroke-color: rgba(0, 0, 0, 0.10);
    font-family: Poppins, sans-serif;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    text-transform: uppercase;
}

.title {
    margin-top: 12px;
    color: #FFF;
    text-align: center;
    text-shadow: 0 1px 0 rgba(0, 0, 0, 0.10);
    font-family: Rubik;
    font-size: 58px;
    font-weight: 900;
    line-height: 48px;
    text-transform: uppercase;
}

.img {
    width: 358px;
    height: 250px;
}


.btnContainer {
    margin-top: 16px;
    border-radius: 100px;
    padding: 8px;
    background-color: rgba(255, 255, 255, 0.2);
}

.btn {
    user-select: none;
    cursor: pointer;
    text-transform: uppercase;
    display: flex;
    font-family: Rubik, sans-serif;
    font-size: 28px;
    font-weight: 700;
    line-height: 27px;
    letter-spacing: 0.01em;
    background: linear-gradient(90deg, #FF6242 0%, #F753B5 53.63%, #5958F7 100%);
    border-radius: 15px;
    box-shadow: 0 1px 0 0 #FFF inset;
    width: 238px;
    height: 58px;
    justify-content: center;
    align-items: center;
    color: #FFFFFF;
    text-shadow: 0 1px 0 rgba(0, 0, 0, 0.50);
}