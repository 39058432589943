.navBtn {
    user-select: none;
    position: relative;
    width: 70px;
    height: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: Poppins, sans-serif;
    font-size: 9px;
    font-weight: 500;
    line-height: 14px;
    text-align: center;
    color: rgba(182, 185, 216, 1);
    span {
        color: rgba(182, 185, 216, 1);
    }
}

.activeClass {
    color: #FFFFFF;
}

.dot {
    background-color: #FF5050;
    height: 8px;
    width: 8px;
    border-radius: 100%;
    position: absolute;
    top: 0;
    right: 15px;
}