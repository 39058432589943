.button {
    user-select: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background: transparent;
    padding: 0;
    border: none;
}

.disabled{
    opacity: 50%;
}
