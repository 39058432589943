#root {
    --font-poppins: 'Poppins', sans-serif;
    --font-prompt: 'Prompt', sans-serif;
    --font-roboto: 'Roboto', sans-serif;
    --font-red-hat-text: 'Red Hat Text', sans-serif;
    --font-monospace: 'Fira Code', 'Fira Mono', monospace; /* Example monospace fonts */
    box-sizing: border-box;
    height: 100%;
}

#root > div:first-of-type {
    height: 100%;
    overscroll-behavior-y: none;
}

:root {
    --footer-height: 80px;
    overscroll-behavior-y: none;
}

a {
    text-decoration: none; /* no underline */
}

*,
*::before,
*::after {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    -webkit-tap-highlight-color: transparent;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
    margin: 0;
    font-family: var(--font-poppins);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    box-sizing: border-box;
    height: 100dvh;
    background-color: #0C0B1A;
    overflow: hidden;
    padding: 0;
    position: relative;
    overscroll-behavior: none;

}

.unity-desktop {
    width: 100% !important;
    height: 100% !important;
}

#unity-container {
    background-color: red !important;
}
.unity-desktop #unity-canvas {
    width: 100% !important;
    height: 100% !important;
}

code {
    font-family: var(--font-monospace);
}

button {
    /*font-family: var(--font-poppins);*/
}

.fade {
    height: 100%;
}


#wave1, #wave2, #wave3 {
    fill: none;
    stroke-width: 1;
    transform-origin: center;
}

#wave1 {
    animation: wave-animation 4.5s infinite;
    animation-delay: -1.5s;
}

#wave2 {
    animation: wave-animation 4.5s infinite;
    animation-delay: -3s;
}

#wave3 {
    animation: wave-animation 4.5s infinite;
    animation-delay: -4.5s;
}

@keyframes wave-animation {
    0% {
        transform: scale(1);
        opacity: 1;
    }
    100% {
        transform: scale(1.6);
        opacity: 0;
    }
}

@keyframes bounce-and-scale {
    0%, 100% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.2);
    }
}

.bounce-and-scale {
    animation-name: bounce-and-scale;
    animation-duration: 0.3s;
    animation-fill-mode: both; /* Retain the end state after animation */
}

@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
        transform: translateY(0);
    }
    40% {
        transform: translateY(-20px);
    }
    60% {
        transform: translateY(-10px);
    }
}

.bounce {
    animation: bounce 2s infinite;
}

@keyframes slideInUp {
    from {
        transform: translateY(100%);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes slideOutDown {
    from {
        transform: translateY(0);
        opacity: 1;
    }
    to {
        transform: translateY(100%);
        opacity: 0;
    }
}
