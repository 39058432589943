.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.99);
}

.modal {
    margin: 0 auto;
    width: 330px;
    height: 330px;
    background: url('../../../../../assets/images/maintenance.jpg') center center no-repeat;
    background-size: cover;
    padding: 24px 32px 40px;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    border-radius: 28px;
    border: 1px solid #745EF5;
    box-shadow: 0 1px 0 0 rgba(255, 255, 255, 0.50) inset;
}

.imgContainer{
    border-radius: 100%;
    min-width: 120px;
    min-height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.10);
}

.image {
    width: 80px;
    height: 80px;
}

.textContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    gap: 8px;
}

.title {
    font-family: Poppins, sans-serif;
    font-size: 18px;
    font-weight: 700;
    line-height: 20px;
    color: #FFFFFF;
}

.text {
    max-width: 265px;
    font-family: Poppins, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    color: #FFFFFF;
}

.btnContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    gap: 8px;
}

.confirmBtn {
    width: 176px;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    background: transparent;
    border: solid 1px rgba(255, 255, 255, 0.5);
    user-select: none;
    cursor: pointer;
    font-family: Poppins, sans-serif;
    font-size: 13px;
    font-weight: 600;
    line-height: 24px;
    color: #FFFFFF;
}

.cancelBtn{
    border-radius: 12px;
    width: 176px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    user-select: none;
    border: none;
    background: #FFFFFF;
    color: #000000;
    font-family: Poppins, sans-serif;
    font-size: 13px;
    font-weight: 600;
    line-height: 24px;
}
